import React, { useState, useEffect } from 'react'
import { Container, Navbar, NavDropdown, Nav } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Link from '../link'
import LanguagePicker from '../languagePicker/languagePicker'
import ThemeToggle from '../themeToggle/themeToggle'
import Logo from '../../assets/svg/logo.svg'

const Header = ({ darkHeader }) => {
    const [scroll, setScroll] = useState(true)
    const [expand, setExpand] = useState(false)
    const { t } = useTranslation('links')

    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrollCheck = darkHeader ? window.scrollY < 5 : window.scrollY < 150
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })
    })
    return (
        <header className={!darkHeader && scroll && !expand ? 'white' : 'black'}>
            <Navbar
                collapseOnSelect
                expand="lg"
                bg={scroll && !expand ? 'transparent' : 'white'}
                onToggle={() => setExpand(!expand)}
            >
                <Container>
                    <Link to="/" className="navbar-brand">
                        <>
                            <Logo className="navLogo" />
                            <span className="rest-of-logo">ρμενακι</span>
                        </>
                    </Link>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to="/restaurant" className="nav-link">
                                {t('restaurant')}
                            </Link>
                            <NavDropdown title={t('rooms')} id="collasible-nav-dropdown">
                                <Link to="/makria" className="dropdown-item">
                                    {t('makria')}
                                </Link>

                                <Link to="/ftena" className="dropdown-item">
                                    {t('ftena')}
                                </Link>
                                <Link to="/pahia" className="dropdown-item">
                                    {t('pahia')}
                                </Link>
                            </NavDropdown>
                            <Link to="/anafi" className="nav-link">
                                {t('anafi')}
                            </Link>
                            <Link to="/contact" className="nav-link">
                                {t('contact')}
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        {/* <ThemeToggle /> */}
                        <LanguagePicker />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

Header.propTypes = {
    darkHeader: PropTypes.bool.isRequired,
}

export default Header
