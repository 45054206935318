import React from 'react'
import PropTypes from 'prop-types'

import Header from './header/header'
import Footer from './footer/footer'
import Wave from './wave/wave'
import 'bootstrap/dist/css/bootstrap.min.css'

const Layout = ({ children, darkHeader }) => (
    <>
        <Header darkHeader={darkHeader} />
        <main>{children}</main>
        <Wave />
        <Footer />
    </>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    darkHeader: PropTypes.bool.isRequired,
}

export default Layout
